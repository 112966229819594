import { faDollar, faFaceSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import VideoSection from "./main/VideosSection";
import MainSearch from "components/search/MainSearch";
import CountUp from "react-countup";
import ZoomPicture from "components/ui-components/zoompicture";

export const MainPage = () => {
  const [count, setCount] = useState(5);
  useEffect(() => {
    // const interval = setInterval(() => {
    //   console.log("Count::", count);
    //   setCount((prevCount) => prevCount + 1);
    // }, 1000);
    // return () => {
    //   clearInterval(interval);
    // };
  }, []);
  return (
    <>
      <section className="py-5 info">
        <div className="container">
          <div className="row align-items-center flex-md-row flex-column-reverse">
            <div className="col-md-6">
              <img
                src="./assets/images/adobe_sign.png"
                alt="Adobe Sign"
                className="img-fluid"
              />
            </div>
            <div className=" col-md-4 px-md-3 px-5">
              <h2 className="mb-4">
                <CountUp end={3300000} duration={20} /> +
              </h2>
            
              <h4 className="web3red">
                WEB3 Domains <br />
                Registered
              </h4>
             
              <div className="d-flex">
                {count > 1 && (
                  <ZoomPicture url="/images/ImageStorage/zoom-1.jpg" />
                )}
                {count > 2 && (
                  <span style={{ marginTop: -15 }}>
                    <ZoomPicture url="/images/ImageStorage/zoom-2.jpg" />
                  </span>
                )}
                {count > 3 && (
                  <span>
                    <ZoomPicture url="/images/ImageStorage/zoom-3.jpg" />
                  </span>
                )}

                {count > 4 && (
                  <span style={{ marginTop: -30 }}>
                    <ZoomPicture url="/images/ImageStorage/zoom-4.jpg" />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="usage py-5">
        <div className="container">
          <h2 className="text-center">Use Your WEB3 Domain Name for</h2>
          <div className="row pt-5">
            <div className="col-md-4 px-lg-5 px-md-4 px-sm-3 text-md-left text-center mb-md-0 mb-3">
              <img src="./assets/images/uicon1.png" alt="Icons" height={50} />
              <h4 className="mt-3 mb-1">Digital Wallet Address</h4>
              <p>
                Your unique identifier for decentralized blockchain transactions
                and digital assets.
              </p>
            </div>
            <div className="col-md-4 px-lg-5 px-md-4 px-sm-3 text-md-left text-center mb-md-0 mb-3 border-left border-right">
              <img src="./assets/images/uicon2.svg" alt="Icons" height={50} />
              <h4 className="mt-3 mb-1">WEB 3 Username</h4>
              <p>
                User-chosen identifier for decentralized web3 applications and
                networks.
              </p>
            </div>
            <div className="col-md-4 px-lg-5 px-md-4 px-sm-3 text-md-left text-center mb-md-0 mb-3">
            <img src="./assets/images/hicon3.svg" alt="Icons" height={50} />
              <h4 className="mt-3 mb-1">Website URL</h4>
              <p>
                Domain names for decentralized web3 applications and websites.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/**VideoSection */}
      <VideoSection />

      <section className="benefits">
        <div className="container">
          <div className="row py-5 align-items-center flex-md-row flex-column-reverse">
            <div className="col-md-6 px-5">
              <img
                src="./assets/images/left-round2.png"
                alt="benefits"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 text-md-left text-center">
              <div className="d-flex align-items-center justify-content-md-start justify-content-center">
                <h6 className="b-subtitle">WEB3 Username</h6>
                <div className="line ml-4" />
              </div>
              <h2 className="b-title my-4">
                A name for your <br /> WEB3{" "}
                <span className="gold">profile</span>
              </h2>
              <p>
                Build your digital identity with Domain Profiles. Verify your
                social accounts, display your NFT avatar, and highlight
                achievements.
              </p>
            </div>
          </div>
          <div className="row py-5 align-items-center">
            <div className="col-md-6 text-md-left text-center">
              <div className="d-flex align-items-center justify-content-md-start justify-content-center">
                <h6 className="b-subtitle">Digital wallet adress</h6>
                <div className="line ml-1 ml-md-4" />
              </div>
              <h2 className="b-title my-4">
                A short <span className="gold">digital wallet</span> name for
                getting paid
              </h2>
              <p>
                Make sending and receiving digital currency simple. Replace the
                long, complicated digital wallet addresses with a single easy to
                read Domain name.
              </p>
              <p className="mt-4 support"> Supported in apps like </p>
              <div className="d-flex align-items-center mb-md-0 mb-4">
                <img
                  src="./assets/images/coinbase.svg"
                  alt="Icons"
                  height={16}
                  className="mr-3"
                />
                <img
                  src="./assets/images/blockchain.svg"
                  alt="Icons"
                  height={16}
                  className="mx-3"
                />
                <img
                  src="./assets/images/rainbow1.png"
                  alt="Icons"
                  height={50}
                  className="mx-3"
                />
              </div>
            </div>
            <div className="col-md-6 px-5">
              <img
                src="./assets/images/right-round2.png"
                alt="benefits"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="benefits browser py-5">
        <div className="container">
          <div className="row py-3 align-items-center flex-md-row flex-column-reverse">
            <div className="col-md-6">
              <img
                src="./assets/images/browsers.png"
                alt="benefits"
                className="img-fluid mb-md-0 mb-4"
              />
              <div className="d-flex align-items-center d-md-none">
                <p className="support mr-4 mb-0"> Supported in apps like </p>
                <img
                  src="./assets/images/brave.svg"
                  alt="Icons"
                  height={22}
                  className="mx-sm-3 mx-1"
                />
                <img
                  src="./assets/images/opera.svg"
                  alt="Icons"
                  height={22}
                  className="mx-sm-3 mx-1"
                />
              </div>
            </div>
            <div className="col-md-6 px-5 mb-md-0 mb-4">
              <div className="d-flex align-items-center justify-content-sm-start justify-content-center">
                <h6 className="b-subtitle">WEB3 Domain Name Address</h6>
                <div className="line ml-sm-4 ml-1" />
              </div>
              <h2 className="b-title my-4 text-md-left text-center">
                A <span className="gold">Domain Name</span> for your WEB3
                website{" "}
              </h2>
              <p className="text-md-left text-center">
                Build a Web3 website with a domain that you fully own and
                control. Your domain is yours for life - no renewal fees, gas
                fees or hosting fees.
              </p>
              <div className="d-none align-items-center d-md-flex">
                <p className="support mr-4 mb-0"> Supported in apps like </p>
                <img
                  src="./assets/images/brave.svg"
                  alt="Icons"
                  height={22}
                  className="mx-sm-3 mx-1"
                />
                <img
                  src="./assets/images/opera.svg"
                  alt="Icons"
                  height={22}
                  className="mx-sm-3 mx-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta py-5">
        <div className="container">
          <div className="row py-3 align-items-center">
            <div className="col-md-6 px-5 text-md-left text-center">
             
              {/* <form className="row inline-form">
                <div className="col-9 p-0">
                  <input
                    type="text"
                    className="form-control rounded-0"
                    placeholder="example.Notary"
                  />
                </div>
                <div className="col-3 p-0">
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block rounded-0"
                  >
                    Search
                  </button>
                </div>
              </form> */}
              
            </div>
            <div className="col-md-6 text-center pt-md-0 pt-5" >
            <h2 className="b-title my-4">So what are you waiting for?</h2>
              <p>Search for your domain today.</p>
           
              <MainSearch />
              
            </div>
            
          </div>
          
        </div>
        
      </section>
    </>
  );
};
export default MainPage;
