
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputHints from "react-input-hints";

export default function MainSearch() {
  const [searchBox, setSearchBox] = useState("");
  const navigate = useNavigate();
  const suggestions = ["Hello World", "ReactJS", "OpenAI"];
  const handleSearch = async () => {
    if (!searchBox) {
      return;
    }
    // v4 - Redirect to new page not same page.. discard below code..
    navigate("/search?result=" + searchBox);
    return false;

    // SECOND_LEVEL_DOMAIN_PLUS_TLD
    // var test = {
    //   result: true,
    //   data: {
    //     result: [
    //       {
    //         type: "EXACT_MATCH",
    //         elements: [
    //           {
    //             availabilityStatus: "AVAILABLE",
    //             name: "mahad.mymaps" + new Date().getMilliseconds(),
    //             asciiName: "mahad.mymaps",
    //             type: "SECOND_LEVEL_DOMAIN_PLUS_TLD",
    //             price: {
    //               currency: "USD",
    //               valueStringed: "448.9",
    //               valueFormatted: "448.90",
    //               amount: 448.9,
    //             },
    //             detailedTld: {
    //               isTldOwned: false,
    //               asciiName: "mymaps",
    //               name: "mymaps",
    //             },
    //             domainPrice: {
    //               currency: "USD",
    //               valueStringed: "49.9",
    //               valueFormatted: "49.90",
    //               amount: 49.9,
    //             },
    //             url: "https://freename.io/results?search=%22mahad.mymaps%22",
    //           },
    //         ],
    //       },
    //     ],
    //     errors: [],
    //   },
    // };
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    return false;
  };
  return (
    <>
      <form className="row inline-form" onSubmit={(e) => formSubmitHandler(e)}>
        <div className="col-9 p-0">
          {/* <input
            type="text"
            className="form-control form-control-lg rounded-0"
            placeholder="example.Notary"
            onChange={(e) => setSearchBox(e.target.value)}
          /> */}
          <InputHints
            className="form-control form-control-lg rounded-0"
            onChange={(e) => setSearchBox(e.target.value)}
            placeholders={[
              "type your name .trump2024",
              "aliciabrown.trump2024",
              "aliciabrown.maga2024",
              "aliciabrown.trump47",
              "aliciabrown.trumpmaga",
            ]}
          />

          {/* <DynamicTypeBox suggestions={suggestions} onChange={setSearchBox} /> */}
        </div>
        <div className="col-3 p-0">
          <button
            type="submit"
            className="btn btn-secondary btn-lg btn-block rounded-0"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </form>
      {/* <div className="status" style={{ display: isLoading ? "block" : "none" }}>
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>
        <div className="shape shape-3"></div>
        <div className="shape shape-4"></div>
      </div> */}
      {/* <div className="mt-4 bg-light">
        <SearchResult data={data} />
      </div> */}
    </>
  );
}
