import { templateSelection, TransactionStatus } from "./enum";

export const extractData = (apiData) => {
  if (apiData?.result && apiData?.data) {
    var data = apiData?.data?.result;
    if (data.length > 0) {
      data = data.find((x) => x.type === "EXACT_MATCH");
      if (data) return data.elements;
    }
  }
  return false;
};
export const pascalCaseToSpaces = (str) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.trim();
};
export const DTO_AddToCart = (data, user) => {
  const dto = {
    itemName: data.name,
    amount: data?.price?.amount,
    currency: data?.price?.currency,
    sid: user.sub,
  };
  return dto;
};
export const ClearCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};
// Usage:
// getCurrencySymbol('en-US', 'CNY') // CN¥
// getCurrencySymbol('zh-CN', 'CNY') // ￥
export const getCurrencySymbol = (locale, currency) => {
  currency = currency || "USD";
  return (0)
    .toLocaleString(locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
};

export const PaymentStatusBadge = (status) => {
  switch (status) {
    case TransactionStatus.None:
      return { type: "danger text-white", text: "None" };
    case TransactionStatus.AddToCart:
      return { type: "warning text-white", text: "Add To Cart" };
    case TransactionStatus.Paid:
      return { type: "success text-white", text: "Paid" };
    case TransactionStatus.Complete:
      return { type: "info text-white", text: "Completed" };
    case TransactionStatus.Remove:
      return { type: "danger text-white", text: "Removed" };
    case TransactionStatus.Transferred:
      return { type: "info text-white", text: "Transferred" };
    case TransactionStatus.Expired:
      return { type: "danger text-white", text: "Expired" };
  }
  return { type: "", text: "None" };
};
export const TampelateBadge = (status) => {
  switch (status) {
    case templateSelection.buildByYouself:
      return { type: "info text-white", text: "Build By Yourself" };
    case templateSelection.Temp1:
      return { type: "warning text-white", text: "Temp 1" };
    case templateSelection.Temp2:
      return { type: "info text-white", text: "Temp 2" };
    case templateSelection.Temp3:
      return { type: "info text-white", text: "Temp 3" };
    case templateSelection.Temp4:
      return { type: "danger text-white", text: "Temp 4" };
    case templateSelection.Temp5:
      return { type: "info text-white", text: "Temp 5" };
  }
  
  return { type: "", text: "" };
};
export const GetDomainDetailsByTLD = (type) => {
  switch (type) {
    case "SECOND_LEVEL_DOMAIN_PLUS_TLD":
      return {
        text: " The price include both domain and TLD",
        isDomain: true,
        isTLD: true,
      };
    case "SECOND_LEVEL_DOMAIN":
      return {
        text: " The price include only domain name",
        isDomain: true,
        isTLD: false,
      };
    case "TLD":
      return {
        text: " The price include only TLD (Top Level Domain)",
        isDomain: false,
        isTLD: true,
      };
    default:
      return { text: "", isDomain: false, isTLD: false };
  }
};
