import React, { useState } from "react";
import VideoModal from "./videoModal";





export const VideoSection = () => {
  const [showYTVideo, setShowYTVideo] = useState();
  const [data, setData] = useState({});

  return (
    <>
      <VideoModal
        showYTVideo={showYTVideo}
        setShowYTVideo={setShowYTVideo}
        videoId={data.videoId}
        title={data.Title}
      />
      <section className="learn py-5">
        <div className="container">
          <h2 className="text-center text-white pt-4">
            {" "}
            Learn More about WEB3 and WEB3 Domains{" "}
          </h2>
          <p className="text-center py-4 mb-0">
            {" "}
            Check out our explainer videos where we provide answers <br /> to
            frequently asked questions.{" "}
          </p>
          <div className="row pt-4">
            <div className="col-lg-3 col-md-4 col-sm-6 px-md-3 px-5">
              <div className="thumb-box">
                <img
                  src="https://img.youtube.com/vi/YFbOCXstXiI/0.jpg"
                  alt=""
                  className="thumbnail img-fluid"
                />
                <img
                  src="./assets/images/play.svg"
                  alt="Play Icon"
                  height={30}
                  className="play-btn"
                  onClick={() => {
                    setData({
                      Title: "The Evolution of the Internet: Web3",
                      videoId: "YFbOCXstXiI",
                    });
                    setShowYTVideo(true);
                  }}
                />
              </div>
              <div className="my-3 play-line" />
              <p className="fw-semibold text-center">
                The Evolution of the Internet: Web3
              </p>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 px-md-3 px-5">
              <div className="thumb-box">
                <img
                  src="https://img.youtube.com/vi/WSDUG0dNxCU/0.jpg"
                  alt=""
                  className="thumbnail img-fluid"
                />
                <img
                  src="./assets/images/play.svg"
                  alt="Play Icon"
                  height={30}
                  className="play-btn"
                  onClick={() => {
                    setData({
                      Title: "Domain Names, Digital Wallets, Digital Identity",
                      videoId: "WSDUG0dNxCU",
                    });
                    setShowYTVideo(true);
                  }}
                />
              </div>
              <div className="my-3 play-line" />
              <p className="fw-semibold text-center">
                Domain Names, Digital Wallets, Digital Identity
              </p>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 px-md-3 px-5">
              <div className="thumb-box">
                <img
                  src="https://img.youtube.com/vi/3J7-7PgcTew/0.jpg"
                  alt=""
                  className="thumbnail img-fluid"
                />
                <img
                  src="./assets/images/play.svg"
                  alt="Play Icon"
                  height={30}
                  className="play-btn"
                  onClick={() => {
                    setData({
                      Title: "Why should you secure a Web3 Domain",
                      videoId: "3J7-7PgcTew",
                    });
                    setShowYTVideo(true);
                  }}
                />
              </div>
              <div className="my-3 play-line" />
              <p className="fw-semibold text-center">
                Why should you secure a Web3 Domain
              </p>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 px-md-3 px-5">
              <div className="thumb-box">
                <img
                  src="https://img.youtube.com/vi/ffwgS_wSPxA/0.jpg"
                  alt=""
                  className="thumbnail img-fluid"
                />
                <img
                  src="./assets/images/play.svg"
                  alt="Play Icon"
                  height={30}
                  className="play-btn"
                  onClick={() => {
                    setData({
                      Title: "Most common FAQ's",
                      videoId: "ffwgS_wSPxA",
                    });
                    setShowYTVideo(true);
                  }}
                />
              </div>
              <div className="my-3 play-line" />
              <p className="fw-semibold text-center">Most common FAQ's</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default VideoSection;
