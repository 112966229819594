export const API_UpdateTemplate = async (id, tempID) => {
  var apiUrl = process.env.REACT_APP_API_URL;
    //debugger;
    const res = await fetch(
      `${apiUrl}/api/transactions/template/${id}/${tempID}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );
    return await res.json();
  };
  