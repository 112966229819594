import { useAuth0 } from "@auth0/auth0-react";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_AddToCart } from "api-services/Cart/AddToCart";
import { API_SearchDomain } from "api-services/Cart/SearchAPI";
import { TransactionStatus } from "helpers/enum";
import {
  DTO_AddToCart,
  GetDomainDetailsByTLD,
  extractData,
} from "helpers/helper";
import React, { useEffect, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { addRangeToCart } from "slices/userSlice";

export default function SearchResultPage() {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  // Access the query string parameters using the get method
  const domain = queryString.get("result");
  const [isLoading, setLoading] = useState(true);
  const [notaryLoading, setNotaryLoading] = useState(true);
  const [notaryPublicLoading, setNotaryPublicLoading] = useState(true);
  const [searchBox, setSearchBox] = useState("");
  const [data, setData] = useState({});
  const [notaryData, setNotaryData] = useState({});
  const [notaryPublicData, setNotaryPublicData] = useState({});
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const myCart = useSelector((state) => state.user.myCart);
  const [addingCartLoading, setAddingCartLoading] = useState(false);

  // code to fetch the searchedDoman while showing loading
  const FetchDomainByName = async (domain) => {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/api/domaindirectory/search?search=` +
        domain,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );
  };
  const FetchDomainInfo = async () => {
    const result = await API_SearchDomain(domain);
    if (extractData(result)) {
      setLoading(false);
      console.log("SearchedDOMAIN::", extractData(result));
      setData(extractData(result));
      // find the domain's first part
      const domainP1 = domainDisplay(domain)?.domainName;
      API_SearchDomain(domainP1 + ".trump2024").then((res) => {
        setNotaryLoading(false);
        setNotaryData(extractData(res));
      });

      API_SearchDomain(domainP1 + ".maga2024").then((res) => {
        setNotaryPublicLoading(false);
        setNotaryPublicData(extractData(res));
      });
      
      API_SearchDomain(domainP1 + ".trump47").then((res) => {
        setNotaryPublicLoading(false);
        setNotaryPublicData(extractData(res));
      });
      API_SearchDomain(domainP1 + ".trumpmaga").then((res) => {
        setNotaryPublicLoading(false);
        setNotaryPublicData(extractData(res));
      });
    } else {
      // show error here
    }
  };
  useEffect(() => {
    FetchDomainInfo();
  }, []);

  const domainDisplay = (domain) => {
    if (domain) {
      domain = domain.split("."); // ['mahad','com']
      return {
        domainName: domain[0],
        tld: domain[1],
      };
    }
  };
  const heading = GetDomainDetailsByTLD(data[0]?.type).text;
  const isProtected = !!(
    data[0]?.availabilityStatus && data[0]?.availabilityStatus.startsWith("PRO")
  );
  const isUnavailable = !!(
    data[0]?.availabilityStatus &&
    data[0]?.availabilityStatus.startsWith("UNAVAILABLE")
  );
  const headingForProtectedDomains =
    "This TLD/Domain is not available since it has been associated with a brand, organization, or notable person.";
  // once result is ready, stop the loading and show the result
  const navigateToSearchTermPage = () => {
    if (searchBox) {
      window.location.href = "/search?result=" + searchBox;
    }
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    return false;
  };
  const handleAddToCart = (data) => {
    //debugger;
    if (!isAuthenticated) {
      toast("Please login before adding items into cart.", {
        autoClose: 3000,
        type: "error",
      });
      return false;
    }
    // Redundant Item
    var itemAlreadyExist = false;
    myCart?.forEach((item) => {
      if (data?.name == item.itemName) {
        itemAlreadyExist = true;
      }
    });
    if (itemAlreadyExist) {
      toast("The domain is already in cart...", {
        autoClose: 3000,
        type: "error",
      });
      return false;
    }
    const newCartItem = DTO_AddToCart(data, user);
    //dispatch(addToCart(newCartItem));
    // update the database
    async function updateMyCart() {
      //debugger;
      setAddingCartLoading(true);
      var apiData = await API_AddToCart(newCartItem);
      //debugger;
      if (apiData?.success) {
        let cartList = [];
        apiData?.result?.usersTransactions?.forEach((item) => {
          if (
            item.status == TransactionStatus.AddToCart ||
            item.status == TransactionStatus.AddToCart
          ) {
            // 1 = Add to cart
            cartList.push(item);
          }
        });
        setAddingCartLoading(false);
        // dispatch range
        dispatch(addRangeToCart(cartList));
      }
    }
    updateMyCart();
  };
  return (
    <>
      <main>
        <section className="section-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="pb-3 sec-head">
                  <h2 className="sec-title pb-4 text-left"> Search Results </h2>
                  <div className="pb-3">
                    <form
                      className="row inline-form search-form mt-4 mx-0"
                      onSubmit={(e) => formSubmitHandler(e)}
                    >
                      <div className="col-9 p-0">
                        <input
                          type="text"
                          className="form-control form-control-lg rounded-0"
                          placeholder="Search for your domain name"
                          onChange={(e) => setSearchBox(e.currentTarget.value)}
                        />
                      </div>
                      <div className="col-3 p-0">
                        <button
                          className="btn btn-dark btn-lg btn-block rounded-0"
                          onClick={navigateToSearchTermPage}
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="options d-md-block d-none mb-5">
                  <a href="#" className="mr-4 opt-active d-inline-block">
                    <i className="fa fa-search" />
                    Results
                  </a>
                   <a href="#" className="mx-4 d-inline-block">
                    <i className="fa-solid fa-bars-filter" />
                    Filter
                  </a>
                  <a href="#" className="mx-4 d-inline-block">
                    <i className="fa-regular fa-heart" />
                    Favorites
                  </a>
                  <a href="#" className="mx-4 d-inline-block">
                    <i className="fa fa-history" /> History
                  </a> 
                </div> */}
                <div className="border search-box">
                  <div className="top px-md-5 py-5">
                    <div className="px-sm-5 px-0">
                      {isLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}

                      {!isLoading && (
                        <>
                          <p className="search-subtitle">
                            The Domain is{" "}
                            <Badge bg="info text-white">
                              {data[0]?.availabilityStatus}
                            </Badge>
                            {addingCartLoading && (
                              <div className="float-right">
                                <Spinner
                                  className="mr-2"
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </p>
                          <h3
                            className={`search-domain ${
                              isUnavailable || isProtected ? "not-found" : ""
                            }`}
                          >
                            {data && (
                              <>{domainDisplay(data[0]?.name).domainName}</>
                            )}
                            .
                            <span className="tld">
                              {data && <>{domainDisplay(data[0]?.name).tld}</>}
                            </span>
                          </h3>
                          {!isUnavailable && !isProtected && (
                            <>
                              <p className="search-price py-3">
                                {data && <>${data[0]?.price?.amount}</>}
                                {".00 "}
                                <small className="note text-muted">
                                  For the one-time fee
                                </small>
                              </p>
                              <button
                                onClick={() => handleAddToCart(data[0])}
                                className="btn btn-primary float-right btn-sm-slim text-uppercase border border-white mr-3 btn-border-primary"
                              >
                                <FontAwesomeIcon
                                  className="icon-fontawesome-fix"
                                  icon={faCartShopping}
                                />
                                Add To Cart
                              </button>
                            </>
                          )}

                          <div className="search-btns">
                            <small className="note text-muted">
                              {isProtected
                                ? headingForProtectedDomains
                                : heading}
                            </small>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="line border-bottom" />
                  {!isLoading && (
                    <>
                      <div className="bottom px-md-5 py-5">
                        <div className="px-sm-5 px-0">
                          <p className="search-offer">Why not secure all ?</p>
                          <table>
                            <tbody>
                              {notaryLoading && (
                                <>
                                  <Spinner
                                    className="mr-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                              {!notaryLoading && (
                                <tr>
                                  <td className="pr-3">
                                    {domainDisplay(domain)?.domainName}.trump2024
                                  </td>
                                  <td>
                                    <Badge bg="info text-white">
                                      {notaryData[0]?.availabilityStatus}
                                    </Badge>{" "}
                                    {notaryData && (
                                      <>${notaryData[0]?.price?.amount}</>
                                    )}
                                    {".00 "}
                                  </td>
                                  <td>
                                    <div className="form-check form-check-lg">
                                      <button
                                        onClick={() =>
                                          handleAddToCart(notaryData[0])
                                        }
                                        className="btn btn-primary float-right btn-sm-slim text-uppercase border border-white mr-3 btn-border-primary"
                                      >
                                        <FontAwesomeIcon
                                          className="icon-fontawesome-fix"
                                          icon={faCartShopping}
                                        />
                                        Add To Cart{" "}
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )}

                              {/** NOTARY PUBLIC BELOW */}
                              {notaryPublicLoading && (
                                <>
                                  <Spinner
                                    className="mr-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                              {!notaryPublicLoading && (
                                <tr>
                                  <td className="pr-3">
                                    {domainDisplay(domain)?.domainName}
                                    .maga2024
                                  </td>
                                  <td>
                                    <Badge bg="info text-white">
                                      {notaryPublicData[0]?.availabilityStatus}
                                    </Badge>{" "}
                                    {notaryPublicData && (
                                      <>${notaryPublicData[0]?.price?.amount}</>
                                    )}
                                    {".00 "}
                                  </td>
                                  <td>
                                    <div className="form-check form-check-lg">
                                      <button
                                        onClick={() =>
                                          handleAddToCart(notaryPublicData[0])
                                        }
                                        className="btn btn-primary float-right btn-sm-slim text-uppercase border border-white mr-3 btn-border-primary"
                                      >
                                        <FontAwesomeIcon
                                          className="icon-fontawesome-fix"
                                          icon={faCartShopping}
                                        />
                                        Add To Cart{" "}
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                               {!notaryPublicLoading && (
                                <tr>
                                  <td className="pr-3">
                                    {domainDisplay(domain)?.domainName}
                                    .trump47
                                  </td>
                                  <td>
                                    <Badge bg="info text-white">
                                      {notaryPublicData[0]?.availabilityStatus}
                                    </Badge>{" "}
                                    {notaryPublicData && (
                                      <>${notaryPublicData[0]?.price?.amount}</>
                                    )}
                                    {".00 "}
                                  </td>
                                  <td>
                                    <div className="form-check form-check-lg">
                                      <button
                                        onClick={() =>
                                          handleAddToCart(notaryPublicData[0])
                                        }
                                        className="btn btn-primary float-right btn-sm-slim text-uppercase border border-white mr-3 btn-border-primary"
                                      >
                                        <FontAwesomeIcon
                                          className="icon-fontawesome-fix"
                                          icon={faCartShopping}
                                        />
                                        Add To Cart{" "}
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!notaryPublicLoading && (
                                <tr>
                                  <td className="pr-3">
                                    {domainDisplay(domain)?.domainName}
                                    .trumpmaga
                                  </td>
                                  <td>
                                    <Badge bg="info text-white">
                                      {notaryPublicData[0]?.availabilityStatus}
                                    </Badge>{" "}
                                    {notaryPublicData && (
                                      <>${notaryPublicData[0]?.price?.amount}</>
                                    )}
                                    {".00 "}
                                  </td>
                                  <td>
                                    <div className="form-check form-check-lg">
                                      <button
                                        onClick={() =>
                                          handleAddToCart(notaryPublicData[0])
                                        }
                                        className="btn btn-primary float-right btn-sm-slim text-uppercase border border-white mr-3 btn-border-primary"
                                      >
                                        <FontAwesomeIcon
                                          className="icon-fontawesome-fix"
                                          icon={faCartShopping}
                                        />
                                        Add To Cart{" "}
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
