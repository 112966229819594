import UserProfileMenu from "./auth/UserProfileMenu";
import { LoginButton } from "./auth/LoginButtons";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";

export const HeaderLayout = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  console.log("USER...", user);

  const homeNavigation = [
    {
      name: "Home",
      to: "/",
    },
    {
      name: "FAQs",
      to: "/faqs",
    },
    {
      name: "About Us",
      to: "/about-us",
    },
    {
      name: "Contact Us",
      to: "/contact-us",
    },
  ];

  return (
    <>
      <div className="preloader">
        <div className="status">
          <div className="shape shape-1" />
          <div className="shape shape-2" />
          <div className="shape shape-3" />
          <div className="shape shape-4" />
        </div>
      </div>

      <header className="sticky-top">
        <div className="top-nav py-2 ">
          <div className="container text-white text-right">
            {/* <a href="#" className="px-3">
              <img src="./assets/images/email.svg" alt="E-mail" height={14} />{" "}
              Email Support
            </a>
            <a href="#" className="px-3">
              Login
            </a> */}
            {/* <div className="float-right"> */}
            {/* Login - START */}
            {!isAuthenticated && <LoginButton />}
            {/*Login- end */}
            {/* User Profile - START */}
            {isAuthenticated && <UserProfileMenu user={user} />}
            {/* User Profile END */}
            {/* </div> */}
          </div>
        </div>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" >
          <div className="container">
            <Navbar.Brand>
              <Link to="/">
                <img
                  src="/assets/images/trump2024domains.png"
                  className="img-fluid"
                  alt="Logo.."
                  style={{
                    height: "60px",
                  }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav "
              className="flex-row-reverse"
            >
              <Nav activeKey={window.location.pathname}>
                {homeNavigation.map((navItem, index) => (
                  <Link
                    to={navItem.to}
                    key={index}
                    className={`from-center nav-link ${
                      navItem.to == window.location.pathname && "active"
                    }`}
                  >
                    {navItem.name}
                  </Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    </>
  );
};
export default HeaderLayout;
