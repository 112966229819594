import React from "react";
export default function PrivacyPolicy() {
  return (
    <main>
      <section className="section-bg lg-text">
        <div className="container">
          <div className="imp-title">
            <h1>Privacy Policy</h1>
          </div>
          <div className="py-5 poppins">
            <p className="fw-semibold">Last Updated: 04/01/2023</p>
            <h5 className="text-center py-3"> AGREEMENT TO TERMS </h5>
            <p>
              This privacy policy (“Policy") describes how trump2024 Ventures, LLC
              d.b.a Supporters of President Trump  and its related companies (“Company") collect,
              use and share personal information of users of this website,
              www.trump2024domains, www.trump2024domains.com as well as any other media
              form, media channel, mobile website or mobile application related,
              linked, or otherwise connected thereto which is controlled by the
              Company (the “Site"), and any related features, functions,
              products, services, materials or other information made available
              through this website and the Company's APIs (collectively, the
              “Services"). This Policy also applies to any of our other websites
              that link to this Policy. This Policy does not apply to websites
              that link to different statements.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">WHAT WE COLLECT</h6>
            <p>
              This privacy policy (“Policy") describes how trump2024 Ventures, LLC
              d.b.a trump2024Web3 and its related companies (“Company") collect,
              use and share personal information of users of this website,
              www.trump2024domains.com as well as any other media
              form, media channel, mobile website or mobile application related,
              linked, or otherwise connected thereto which is controlled by the
              Company (the “Site"), and any related features, functions,
              products, services, materials or other information made available
              through this website and the Company's APIs (collectively, the
              “Services"). This Policy also applies to any of our other websites
              that link to this Policy. This Policy does not apply to websites
              that link to different statements.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">REPRESENTATIONS</h6>
            <p>
              The information you enter on our Site or Services can be published
              to the blockchain through the use of our software that allows you
              to broadcast a transaction to the blockchain network. Once
              broadcast to the blockchain network, we cannot control how others
              may use your personal information. Please think of your blockchain
              domain as a “Public Profile" much like any other social account
              and realize that others will have access to the information that
              you direct us to publish. Specifically, if you post information
              about yourself in our management portal and then save this
              information to the blockchain, the information is now publicly
              available forever. <br />
              <br />
            </p>
            <p className="mb-1">
              {" "}
              At Unstoppable Domains, we use your personal information as
              follows:{" "}
            </p>
            <ul className="mb-1 pl-4">
              <li>
                We use your personal information to operate, maintain, and
                improve our Sites, products, and Services.
              </li>
              <li>
                We use your personal information to respond to comments and
                questions and provide customer service.
              </li>
              <li>
                We use your personal information to send information including
                confirmations, invoices, technical notices, updates, security
                alerts, and support and administrative messages.
              </li>
              <li>
                We use your personal information to communicate about
                promotions, upcoming events, and other news about products and
                services offered by us and our selected partners.
              </li>
              <li>
                We use your personal information to protect, investigate, and
                deter against fraudulent, unauthorized, or illegal activity.
              </li>
            </ul>
            <h6 className="fw-semibold mt-5 mb-3">
              SHARING OF PERSONAL INFORMATION
            </h6>
            <p className="mb-1">
              {" "}
              We may share personal information as follows:{" "}
            </p>
            <ul className="mb-1 pl-4">
              <li>
                We may share personal information with your consent. For
                example, you may share personal information with others by
                publishing that information to the blockchain. Please understand
                that once this information is published, in some cases it cannot
                be unpublished (as one of the chief purposes of blockchain
                systems is to enable data provenance through ensuring accurate
                and immutable record keeping).
              </li>
              <li>
                We may share personal information when we do a business deal, or
                negotiate a business deal, involving the sale or transfer of all
                or a part of our business or assets. These deals can include any
                merger, financing, acquisition, or bankruptcy transaction or
                proceeding.
              </li>
              <li>
                We may share personal information for legal, protection, and
                safety purposes. We may share information to comply with
                applicable laws.
              </li>
              <li>
                We may share information to respond to lawful requests and legal
                processes.
              </li>
              <li>
                We may share information to protect the rights and property of
                trump2024 Ventures LLC., our agents, customers, and others. This
                includes enforcing our agreements, policies, and terms of use.
              </li>
              <li>
                We may share information in an emergency. This includes
                protecting the safety of our employees and agents, our
                customers, or any person.
              </li>
              <li>
                We may share information with those who need it to do work for
                us.
              </li>
            </ul>
            <h6 className="fw-semibold mt-5 mb-3">
              DECENTRALIZED APPLICATIONS AND UD.ME EMAIL ADDRESSES
            </h6>
            <p>
              We may share UD.me email addresses with third party decentralized
              applications (“DAPPs") for the purposes of improving your user
              experience. To the extent your UD.me email address contains
              personal information, this personal information may be shared with
              the DAPP who will be a separate and independent controller of this
              personal information. Where we transfer your personal information
              to a DAPP, we will take reasonable steps to ensure that such DAPP
              has appropriate technical and organizational in place to protect
              your personal information and comply with applicable data
              protection laws. For further information about how a DAPP
              processes your personal information, please visit their privacy
              policy, generally available on their website.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              INFORMATION CHOICES AND CHANGES
            </h6>
            <p>
              Our marketing emails tell you how to “opt-out." If you opt out, we
              may still send you non-marketing emails. Non-marketing emails
              include emails about your accounts and our business dealings with
              you. You may send requests about personal information to our
              Contact Information below. You can request to change contact
              choices, opt-out of our sharing with others, and update your
              personal information. Many browsers are set to accept cookies
              until you change your settings. If you would prefer not to accept
              cookies, most browsers will allow you to: (i) change your browser
              settings to notify you when you receive a cookie, which lets you
              choose whether or not to accept it; (ii) disable existing cookies;
              or (iii) set your browser to automatically reject cookies. Please
              note that doing so may negatively impact your experience using the
              sites, as some features and services on our sites may not work
              properly. Depending on your mobile device and operating system,
              you may not be able to delete or block all cookies. You may also
              set your e-mail options to prevent the automatic downloading of
              images that may contain technologies that would allow us to know
              whether you have accessed our e-mail and performed certain
              functions with it. You may also be able to limit interest-based
              advertising through the settings on your mobile device by
              selecting “limit ad tracking" (iOS) or “opt-out of interest-based
              ads" (Android). If you remove or reject our cookies, it could
              affect how our Site works for you.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              LINKS TO THIRD-PARTY WEBSITES AND SERVICES
            </h6>
            <p>
              For your own convenience, our Site or Services may provide links
              to third-party websites or services linked to or form the
              Services, including the information or content contained within
              them. Your browsing and interaction on any other website or
              service are subject to the applicable third party's rules and
              policies, not ours. If you are using a third-party website or
              service, you do so at your own risk. We encourage you to review
              the privacy policies of any site or service before providing any
              personal information.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">CHILDREN'S PRIVACY</h6>
            <p>
              Our Services are not intended for children under the age of 16. We
              do not knowingly solicit or collect personal information from
              children under the age of 16. If we learn that any personal
              information has been collected inadvertently from a child under
              16, we will delete the information as soon as possible. If you
              believe that we might have collected information from a child
              under 16, please contact us at{" "}
              <a className="text-underline" href="mailto:privacy@trump2024domains.com">
                privacy@trump2024domains
              </a>
              .
            </p>
            <h6 className="fw-semibold mt-5 mb-3">CONTACT INFORMATION</h6>
            <p>
              We welcome your comments or questions about this Privacy Policy.
              Contact us at our address: trump2024domains Inc. 3375 S Rainbow Blvd
              #81710, Las Vegas, NV, 89180or by email at{" "}
              <a className="text-underline" href="mailto:privacy@trump2024domains.com">
              privacy@trump2024domains
              </a>
              .
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              GLOBAL APPLICABILITY AND REGION-SPECIFIC DISCLOSURES
            </h6>
            <p>
              This privacy policy is designed to apply to our Site visitors,
              users of our Services and other companies and users on a global
              basis. We may choose or be required by law to provide additional
              disclosures relating to the processing of personal information in
              certain countries, regions, or states. Please refer below to
              disclosures that may be applicable to you.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">CALIFORNIA PRIVACY RIGHTS</h6>
            <p>
              If you are a California resident, please see the “California
              Privacy Disclosures" section below for more information.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">NEVADA PRIVACY RIGHTS</h6>
            <p>
              Chapter 603A of the Nevada Revised Statutes permits a Nevada
              resident to opt out of future sales of certain covered information
              that a website operator has collected or will collect about the
              resident. We do not sell your personal information within the
              meaning of Chapter 603A. However, if you would still like to
              submit such a request, please contact us at{" "}
              <a className="text-underline" href="mailto:privacy@trump2024domains.com">
              privacy@trump2024domains
              </a>
              .
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              EUROPEAN ECONOMIC AREA, UNITED KINGDOM OR SWITZERLAND
            </h6>
            <p>
              If you are located in the European Economic Area (“EEA"), the
              United Kingdom or Switzerland, or otherwise engage with
              Unstoppable Domain's European operations, please see the “Privacy
              Disclosures for the European Economic Area, United Kingdom and
              Switzerland" for additional European-specific privacy disclosures,
              including what constitutes your personal information, the lawful
              bases we rely on to process your personal information, how we use
              cookies when you access our Site or Services from the EEA, UK or
              Switzerland and your rights in respect of your personal
              information.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              NOTE FOR INTERNATIONAL VISITORS
            </h6>
            <p>
              Personal information may be transferred to, stored and processed
              in a country other than the one in which it was collected. For
              example, the Site and Services are primarily hosted in and
              provided from the United States. Please note the country to which
              personal data is transferred may not provide the same level of
              protection for personal information as the country from which it
              was transferred.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              California Privacy Disclosures
            </h6>
            <p>
              This section applies to you if you are a resident of the state of
              California. California law requires us to disclose certain
              information regarding the categories of information we collect.
              For purposes of this section, “personal information" has the
              meaning provided by the California Consumer Privacy Act (the
              “CCPA"). This section does not apply to information relating to
              our employees, contractors, applicants, and other personnel.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              Collection, Disclosure, and Sale of Personal Information
            </h6>
            <p>
              In the last 12 months, we have not sold personal information about
              you, but we have collected and disclosed for a business purpose
              the following categories and examples of specific pieces of
              personal information:
            </p>
            <table className="table privacy-tables table-striped table1 my-5">
              <thead className="text-left">
                <tr>
                  <th>Category of Personal Information</th>
                  <th className="text-center">Collected</th>
                  <th className="text-center">
                    Disclosed for Business Purposes
                  </th>
                  <th>Sold*</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Identifiers, including relating to data used to identify
                    you, such as full name, email, address, birthdate (month and
                    day), and phone number.
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>
                    Protected Classification Characteristics, including age,
                    gender, and sex.
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>
                    Commercial Information, such as account history and products
                    or services purchased, obtained, or considered.
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>
                    Internet/Network Information, such as device information,
                    logs, IP address, and analytics data.
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td>No Personal Information Sold</td>
                </tr>
                <tr>
                  <td>
                    Geolocation Data, including your general geographic
                    location.
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>
                    Sensory Information, such as photo or avatar, videos, and
                    recordings of phone calls between you and us, where
                    permitted by law.
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>
                    Other Personal Information, including communication
                    preferences, customer service and communications history,
                    personal information you permit us to see when interacting
                    with us through social media, and personal information you
                    provide us in relation to a question, request, inquiry,
                    survey, contest or promotion.
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>
                    Inferences, including our predictions about your interests
                    and preferences and related Service Profile Information.
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td className="text-center checks">
                    <i className="fa fa-check" />
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
            <h6 className="fw-semibold mt-5 mb-3">* Note about "Sales":</h6>
            <p>
              We do not “sell" personal information as most people think of that
              term. However, we do utilize third-party providers to engage in
              online analytics and advertising involving the collection of
              personal information, including Identifiers, Internet/Network
              Information, and Geolocation Data, directly from your browser or
              device through cookies or related technologies when you visit or
              interact with our online services or otherwise engage with us
              online. We use these third-party providers to analyze and optimize
              our online services, provide more relevant content and ads to
              users of our online services and other third parties, and perform
              other services related to analytics or advertising consistent with
              these Disclosures and our Privacy Policy. These third-party
              businesses may use the information they collect directly from you
              for their own purposes in accordance with their own privacy
              notices, which may include using this information for services
              provided to other third parties, including other parties operating
              in a dvertising networks.{" "}
            </p>
            <p>
              {" "}
              To the extent that these practices qualify as “sales" under the
              CCPA, you may have the right to opt out as described in the Your
              Privacy Rights section below.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              Sources of Personal Information
            </h6>
            <p>
              As described in the What We Collect and Use of Personal
              Information sections of our Privacy Policy, we collect personal
              information directly from you when you provide it to us,
              automatically when you visit our websites and other online
              services, and from service providers and other third parties. For
              representatives of our clients, vendors, service providers and
              other third parties, we also collect personal information directly
              from you and from the entity you represent.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              Purposes for Collecting Personal Information
            </h6>
            <p>
              We collect personal information about you for the purposes
              described in the What We Collect and Use of Personal Information
              section of our Privacy Policy. For representatives of our clients,
              vendors, service providers and other third parties, we also
              collect your personal information to maintain an ongoing
              relationship between us and the entity you represent and to
              contact you in connection with our relationship with the entity
              you represent.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">
              Recipients of Personal Information
            </h6>
            <p>
              As described in the Sharing of Personal Information section of our
              Privacy Policy, we share personal information with a variety of
              third parties. For representatives of our clients, vendors,
              service providers and other third parties, we also share your
              personal information with the entity you represent.
            </p>
            <h6 className="fw-semibold mt-5 mb-3">Your Privacy Rights</h6>
            <p>
              As a California resident, you may be able to exercise the
              following rights in relation to the Personal Information about you
              that we have collected (subject to certain limitations at law):
            </p>
            <p>
              {" "}
              The right to request any or all of the following personal
              information we have collected and disclosed about you in the last
              12 months:
            </p>
            <table className="table privacy-tables table2 my-5">
              <tbody>
                <tr>
                  <th>Right to Know</th>
                  <td>
                    <ul>
                      <li>
                        The specific pieces of personal information we have
                        collected about you;
                      </li>
                      <li>
                        The categories and specific pieces of personal
                        information we have collected about you;
                      </li>
                      <li>
                        The categories of sources of the personal information we
                        have collected about you;
                      </li>
                      <li>
                        The categories of personal information we have disclosed
                        about you to third parties for a business purpose, and
                        the categories of recipients to whom this information
                        was disclosed;
                      </li>
                      <li>
                        The categories of personal information we have sold
                        about you (if any), and the categories of third parties
                        to whom this information was sold; and
                      </li>
                      <li>
                        The business or commercial purposes for collecting or,
                        if applicable, selling personal information.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Right to Deletion</th>
                  <td>
                    <ul>
                      <li>
                        The right to request the deletion of personal
                        information we have collected from you, subject to
                        certain exceptions.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Right to Opt Out</th>
                  <td>
                    <ul>
                      <li>
                        The right to direct us not to sell personal information
                        we have collected about you to third parties.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Right to Non- Discrimination</th>
                  <td>
                    <ul>
                      <li>
                        The right to non-discrimination does not prohibit us
                        from offering you certain financial incentives that may
                        result in different prices, rates or quality levels of
                        products or services. In addition, please note that if
                        the exercise of the rights described above limits our
                        ability to process personal information (such as in the
                        case of a deletion request), we may not be able to
                        provide our products and services to individuals who
                        exercise these rights, or to otherwise engage with such
                        individuals going forward.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <h6 className="fw-semibold mt-5 mb-3">Submitting Requests</h6>
            <p className="mt-4 fw-semibold">
              To Exercise Your Right to Know or Right to Deletion
            </p>
            <p>
              To submit a request to exercise your right to know or right to
              deletion, please submit a request by mail to our business address
              at 3375 S Rainbow Blvd #81710, Las Vegas, NV, 89180or by emailing
              us at  privacy@trump2024domains. Before processing your request, we will
              need to verify your identity and confirm you are a resident of the
              State of California. This process may require us to request
              additional personal information from you, including, but not
              limited to, your email, validation of your residential or business
              address, product website name, and/or date of last interaction
              with customer service. In certain circumstances, we may decline a
              request to exercise the right to know and/or right to deletion,
              particularly where we are unable to verify your identity.
            </p>
            <p className="mt-4 fw-semibold">Authorized Agents</p>
            <p>
              In certain circumstances, you are permitted to use an authorized
              agent (as that term is defined by the CCPA) to submit requests on
              your behalf through the designated methods set forth in these
              Disclosures where we can verify the authorized agent's authority
              to act on your behalf by:
            </p>
            <ol className="ol1">
              <li>For requests to know or delete personal information:</li>
              <ol className="l-alpha">
                <li>
                  receiving a power of attorney valid under the laws of
                  California from you or your authorized agent; or
                </li>
                <li>receiving sufficient evidence to show that you have:</li>
                <ol className="l-roman">
                  <li>
                    provided the authorized agent signed permission to act on
                    your behalf;
                  </li>
                  <li>
                    verified your own identity directly with us pursuant to the
                    instructions set forth in these Disclosures; and
                  </li>
                  <li>
                    directly confirmed with us that you provided the authorized
                    agent permission to submit the request on your behalf.
                  </li>
                </ol>
              </ol>
              <li>
                For requests to opt-out of personal information “sales":
                receiving a signed permission demonstrating your authorized
                agent has been authorized by you to act on your behalf.
              </li>
            </ol>
            <p className="mt-4 fw-semibold">
              To Exercise Your Right to Opt Out
            </p>
            <p>
              As described in the Collection, Disclosure and Sale of Personal
              Information section above, we utilize third-party providers to
              engage in online analytics and advertising involving the
              collection of personal information, including Identifiers,
              Internet/Network Information, and Geolocation Data, directly from
              your browser or device through cookies or related technologies
              when you visit or interact with our online services or otherwise
              engage with us online. We use these third-party providers to
              analyze and optimize our online services, provide more relevant
              content and ads to users of our online services and other third
              parties, and perform other services related to analytics or
              advertising consistent with these Disclosures and our Privacy
              Policy. These third-party businesses may use the information they
              collect directly from you for their own purposes in accordance
              with their own privacy notices, which may include using this
              information for services provided to other third parties,
              including other parties operating in advertising networks.{" "}
            </p>
            <p>
              {" "}
              To the extent that these practices qualify as “sales" under the
              CCPA, you may have the right to opt out as described above. Please
              see Information Choices and Changes section of our Privacy Policy
              for more information about the choices and opt-out options you may
              have in relation to those practices.
            </p>
            <p className="mt-4 fw-semibold">
              California's “Shine the Light" Law
            </p>
            <p>
              California's “Shine the Light" law (Civil Code Section §1798.83)
              provides certain rights to California residents that have an
              established business relationship with us with regard to the
              disclosure of certain types of personal information to third
              parties for their direct marketing purposes. To opt-out of having
              your personal information disclosed to third parties for their
              direct marketing purposes, please contact us by mailing your
              request to 3375 S Rainbow Blvd #81710, Las Vegas, NV, 89180and
              provide your contact information to be added to our suppression
              list.
            </p>
            <p className="mt-4 fw-semibold">Updates to These Disclosures</p>
            <p>
              We will update these Disclosures from time to time. When we make
              changes to these Disclosures, we will change the “Last Modified"
              date. If we make material changes to these Disclosures, we will
              notify you by prominent posting at the beginning of our Privacy
              Policy.
            </p>
            <p className="mt-4 fw-semibold">
              PRIVACY DISCLOSURES FOR THE EUROPEAN ECONOMIC AREA (EEA), UNITED
              KINGDOM AND SWITZERLAND
            </p>
            <p>
              We are located in the United States. However, if you are an EEA
              resident, you have additional rights under the EU General Data
              Protection Regulation (GDPR), and trump2024domains Inc. is a
              “Controller" of your personal information thereunder, and you are
              a “Data Subject". When we use the term “personal data" in this
              section, we mean information relating to an identified or
              identifiable natural person.
            </p>
            <ol className="my-4 pl-3">
              <li>
                <p>
                  PERSONAL DATA WE COLLECT FROM YOU WHEN YOU USE trump2024domains'S
                  EUROPEAN SERVICES
                </p>
                <p>
                  {" "}
                  We collect the categories of personal data that you
                  voluntarily submit directly to us when you use the European
                  Services, as set forth in our Privacy Policy under the section
                  entitled “What We Collect." The table at Annex 1 sets out in
                  detail the categories of personal data we collect about you
                  and how we use that information when you use the European
                  Services, as well as the legal basis which we rely on to
                  process the personal information and recipients of that
                  personal information.{" "}
                </p>
              </li>
              <li>
                <p>INFORMATION WE COLLECT ABOUT YOU AUTOMATICALLY</p>
                <p className="mb-1">
                  We also automatically collect personal information indirectly
                  about how you access and use the European Services, and
                  information about the device you use to access the European
                  Services. For example, we may collect:
                </p>
                <ol className="l-alpha">
                  <li>
                    information about the features you use and the pages you
                    view on the European Services;
                  </li>
                  <li>
                    information about your device (such as your IP address,
                    device identifier, device type, model and manufacturer); and
                  </li>
                  <li>
                    information about your usage patterns (such as how often you
                    use the Unstoppable Domain European Services and your
                    language settings).
                  </li>
                </ol>
                <p>
                  We use this information to provide you the features and
                  functionality of the European Services, to monitor and improve
                  the European Services and to develop new services. The table
                  at Annex 2 sets out further information about the categories
                  of personal information we collect about you automatically and
                  how we use that information. The table also lists the legal
                  basis which we rely on to process the personal information and
                  recipients of that personal information. We may link or
                  combine the personal information we collect about you and the
                  information we collect automatically. We may anonymize and
                  aggregate any of the personal information we collect (so that
                  it does not directly identify you). We may use anonymized
                  information for purposes that include testing our IT systems,
                  research, data analysis, improving the trump2024domains European
                  Services. We may also share such anonymized and aggregated
                  information with others.
                </p>
              </li>
              <li>
                <p>HOW LONG WILL WE STORE YOUR PERSONAL INFORMATION</p>
                <p>
                  We will usually store the personal information we collect
                  about you for no longer than necessary for the purposes set
                  out in Annex 1 and Annex 2, in accordance with our legal
                  obligations and legitimate business interests. Where we retain
                  information pursuant to Legal Obligation, we retain such
                  information for 7 years. <br /> The criteria used to determine
                  the period for which personal information about you will be
                  retained varies depending on the legal basis under which we
                  process the personal information:{" "}
                </p>
                <ol className="l-alpha">
                  <li>
                    Legitimate Interests. Where we are processing personal
                    information based on our legitimate interests, we generally
                    will retain such information for a reasonable period of time
                    based on the particular interest, taking into account the
                    fundamental interests and the rights and freedoms of data
                    subjects.
                  </li>
                  <li>
                    Consent. Where we are processing personal information based
                    on your consent, we generally will retain the information
                    until you withdraw your consent, or otherwise for the period
                    of time necessary to fulfil the underlying agreement with
                    you or provide you with the applicable service for which we
                    process that personal information.
                  </li>
                  <li>
                    Contract. Where we are processing personal information based
                    on contract, we generally will retain the information for
                    the duration of the contract plus some additional limited
                    period of time that is necessary to comply with law or that
                    represents the statute of limitations for legal claims that
                    could arise from the contractual relationship.
                  </li>
                  <li>
                    Legal Obligation. Where we are processing personal
                    information based on a legal obligation, we generally will
                    retain the information for the period of time necessary to
                    fulfil the legal obligation.
                  </li>
                  <li>
                    Legal Claim. We may need to apply a "legal hold" that
                    retains information beyond our typical retention period
                    where we face threat of legal claim. In that case, we will
                    retain the information until the hold is removed, which
                    typically means the claim or threat of claim has been
                    resolved.
                  </li>
                </ol>
                <p>
                  In all cases, in addition to the purposes and legal bases, we
                  consider the amount, nature and sensitivity of the personal
                  information, as well as the potential risk of harm from
                  unauthorised use or disclosure of your personal information.
                </p>
              </li>
              <li>
                <p>RECIPIENTS OF PERSONAL INFORMATION</p>
                <p>
                  In addition to the recipients listed in Annexes 1 and 2, we
                  may also share your personal information with the following
                  (as required in accordance with the uses set out in Annexes 1
                  and 2):{" "}
                </p>
                <ol>
                  <li>
                    Service providers and advisors: we may share your personal
                    information with third party vendors and other service
                    providers that perform services for us or on our behalf,
                    which may include providing professional services, such as
                    legal and accounting services, mailing, email or chat
                    services, fraud prevention, web hosting, or providing
                    analytic services.
                  </li>
                  <li>
                    Affiliates. Other companies owned by or under common
                    ownership as Unstoppable Domains, including our subsidiaries
                    (i.e., any organization we own or control) and our ultimate
                    holding company (i.e., any organization that owns or
                    controls us) and any subsidiaries it owns. These companies
                    will use your personal information in the same way as we can
                    under these Privacy Disclosures
                  </li>
                  <li>
                    Purchasers and third parties in connection with a business
                    transaction: your personal information may be disclosed to
                    third parties in connection with a transaction, such as a
                    merger, sale of assets or shares, reorganization, financing,
                    change of control or acquisition of all or a portion of our
                    business.
                  </li>
                  <li>
                    Law enforcement, regulators and other parties for legal
                    reasons: we may share your personal information with third
                    parties as required by law or if we reasonably believe that
                    such action is necessary to (i) comply with the law and the
                    reasonable requests of law enforcement; (ii) detect and
                    investigate illegal activities and breaches of agreements,
                    including our Terms; and/or (iii) exercise or protect the
                    rights, property, or personal safety of Unstoppable Domains,
                    its users or others.
                  </li>
                </ol>
              </li>
              <li>
                {" "}
                MARKETING AND ADVERTISING{" "}
                <p>
                  From time to time we may contact you with information about
                  our services, including sending you marketing messages and
                  asking for your feedback on our services. Most marketing
                  messages we send will be by email. For some marketing
                  messages, we may use personal information we collect about you
                  to help us determine the most relevant marketing information
                  to share with you. <br /> We will only send you marketing
                  messages if you have given us your consent to do so. You can
                  withdraw your consent at a later date by clicking on the
                  unsubscribe link at the bottom of our marketing emails or by
                  updating your preferences via your account on the Site.{" "}
                </p>
              </li>
              <li>
                <p>STORING AND TRANSFERRING YOUR PERSONAL INFORMATION</p>
                <p>
                  Security. We implement appropriate technical and
                  organizational measures to protect your personal information
                  against accidental or unlawful destruction, loss, change or
                  damage. All personal information we collect will be stored by
                  our cloud hosting provider on secure servers. We will never
                  send you unsolicited emails or contact you by phone requesting
                  credit or debit card information or national identification
                  numbers. <br /> International Transfers of your Personal
                  Information. The personal information we collect may be
                  transferred to and stored in countries outside of the
                  jurisdiction you are in where we and our third-party service
                  providers have operations. If you are located in the EEA,
                  United Kingdom or Switzerland, your personal information may
                  be processed outside of those regions, including in the United
                  States. <br /> In the event of such a transfer, we ensure
                  that: (i) the personal information is transferred to countries
                  recognized as offering an equivalent level of protection; or
                  (ii) the transfer is made pursuant to appropriate safeguards,
                  such as standard data protection clauses adopted by the
                  European Commission. <br /> If you wish to enquire further
                  about these safeguards used, please contact us using the
                  details set out at the end of these Privacy Disclosures.{" "}
                </p>
              </li>
              <li>
                <p>PROFILING </p>
                <p>
                  We may also use personal data about you to detect and reduce
                  fraud. and credit risk.
                </p>
              </li>
              <li>
                <p>YOUR RIGHTS IN RESPECT OF YOUR PERSONAL INFORMATION </p>
                <ol className="l-alpha">
                  <li>
                    In accordance with applicable privacy law, you have the
                    following rights in respect of your personal information
                    that we hold:
                  </li>
                  <li>Right of access. You have the right to obtain:</li>
                  <li>
                    confirmation of whether, and where, we are processing your
                    personal information;
                    <ol className="l-roman">
                      <li>
                        information about the categories of personal information
                        we are processing, the purposes for which we process
                        your personal information and information as to how we
                        determine applicable retention periods;
                      </li>
                      <li>
                        information about the categories of recipients with whom
                        we may share your personal information; and
                      </li>
                      <li>
                        a copy of the personal information we hold about you.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Right of portability. You have the right, in certain
                    circumstances, to receive a copy of the personal information
                    you have provided to us in a structured, commonly used,
                    machine-readable format that supports re-use, or to request
                    the transfer of your personal data to another person.
                  </li>
                  <li>
                    Right to rectification. You have the right to obtain
                    rectification of any inaccurate or incomplete personal
                    information we hold about you without undue delay.
                  </li>
                  <li>
                    Right to erasure. You have the right, in some circumstances,
                    to require us to erase your personal information without
                    undue delay if the continued processing of that personal
                    information is not justified.
                  </li>
                  <li>
                    Right to restriction. You have the right, in some
                    circumstances, to require us to limit the purposes for which
                    we process your personal information if the continued
                    processing of the personal information in this way is not
                    justified, such as where the accuracy of the personal
                    information is contested by you.
                  </li>
                  <li>
                    Right to withdraw consent. There are certain circumstances
                    where we require your consent to process your personal
                    information. In these instances, and if you have provided
                    consent, you have the right to withdraw your consent. If you
                    withdraw your consent, this will not affect the lawfulness
                    of our use of your personal information before your
                    withdrawal.
                  </li>
                  <p>
                    You also have the right to object to any processing based on
                    our legitimate interests where there are grounds relating to
                    your particular situation. There may be compelling reasons
                    for continuing to process your personal information, and we
                    will assess and inform you if that is the case. You can
                    object to marketing activities for any reason. <br /> You
                    also have the right to lodge a complaint to your local data
                    protection authority. If you are based in the European
                    Union, information about how to contact your local data
                    protection authority is available here. If you are based in
                    the UK or Switzerland, your local data protection
                    authorities are the UK Information Commissioner's Office (
                    <a
                      href="https://ico.org.uk/global/contact-us/"
                      className="text-underline"
                    >
                      https://ico.org.uk/global/contact-us/
                    </a>{" "}
                    ) and the Swiss Federal Data Protection and Information
                    Commissioner (
                    <a
                      className="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact/address.html"
                      href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact/address.html"
                    >
                      https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact/address.html
                    </a>
                    ).
                  </p>
                  <p>
                    If you wish to exercise one of these rights, please contact
                    us using the contact details at the end of these Privacy
                    Disclosures. <br /> Due to the confidential nature of data
                    processing we may ask you to provide proof of identity when
                    exercising the above rights. This can be done by providing a
                    scanned copy of a valid identity document or a signed
                    photocopy of a valid identity document .
                  </p>
                </ol>
              </li>
              <li>
                <p>
                  COOKIES AND SIMILAR TECHNOLOGIES USED ON OUR EUROPEAN SERVICES
                </p>
                <p>
                  Our European Services uses cookies and similar technologies
                  such as pixels and Local Storage Objects (LSOs) like HTML5
                  (together "cookies") to distinguish you from other users of
                  our European Services. This helps us to provide you with a
                  good experience when you browse our European Services and also
                  allows us to monitor and analyse how you use and interact with
                  our European Services so that we can continue to improve our
                  European Services. It also helps us and our advertising
                  partners to determine products and services that may be of
                  interest to you, in order to serve you targeted
                  advertisements. Cookies are pieces of code that allow for
                  personalization of our European Services experience by saving
                  your information such as user ID and other preferences. A
                  cookie is a small data file that we transfer to your
                  computer's hard disk for record-keeping purposes.{" "}
                </p>
                <p>We use the following types of cookies:</p>
                <ol className="l-alpha">
                  <li>
                    Strictly necessary cookies. These are cookies that are
                    required for the operation of our European Services. They
                    include, for example, cookies that enable you to log into
                    secure areas of our European Services.
                  </li>
                  <li>
                    Analytical/performance cookies. They allow us to recognize
                    and count the number of visitors and to see how visitors
                    move around our European Services when they are using it.
                    This helps us to improve the way our European Services
                    works, for example, by ensuring that users are finding what
                    they are looking for easily.
                  </li>
                  <li>
                    Functionality cookies. These are used to recognize you when
                    you return to our European Services. This enables us to
                    personalize our content for you, greet you by name and
                    remember your preferences (for example, your choice of
                    language or region).
                  </li>
                  <li>
                    Targeting cookies. These cookies record your visit to our
                    European Services, the pages you have visited and the links
                    you have followed. We will use this information to make our
                    European Services and the advertising displayed on it, and
                    the marketing messages we send to you more relevant to your
                    interests. We may also share this information with third
                    parties who provide services to us for this purpose.
                  </li>
                  <li>
                    Third party cookies. Please be aware that advertisers and
                    other third parties may use their own cookies tags when you
                    click on an advertisement or link on our European Services.
                    These third parties are responsible for setting out their
                    own cookie and privacy policies.
                  </li>
                </ol>
                <p>
                  {" "}
                  Please see Annex 3 for more information about the cookies we
                  use on the European Services. <br /> Other than strictly
                  necessary cookies, which are required for the operation of our
                  European Services, we will only place cookies on your device
                  if you give us your consent to do so. We will ask you to tell
                  us which cookies you agree to receive when you first access
                  our European Services. <br /> Most browsers also allow you to
                  change your cookie settings to block certain cookies.
                  Depending on your mobile device and operating system, you may
                  not be able to delete or block all cookies. Please note that
                  if you choose to refuse all cookies you may not be able to use
                  the full functionality of our European Services. These
                  settings will typically be found in the "options" or
                  "preferences" menu of your browser. In order to understand
                  these settings, the following links may be helpful, otherwise
                  you should use the "Help" option in your browser for more
                  details.{" "}
                </p>
                <p>
                  <a href="#" className="text-underline text-primary">
                    Cookie settings in Internet Explorer
                  </a>
                  <br />
                  <a href="#" className="text-underline text-primary">
                    Cookie settings in Firefox
                  </a>
                  <br />
                  <a href="#" className="text-underline text-primary">
                    Cookie settings in Chrome{" "}
                  </a>{" "}
                  <br />
                  <a href="#" className="text-underline text-primary">
                    Cookies settings in Safari web
                  </a>
                  and{" "}
                  <a href="#" className="text-underline text-primary">
                    iOS
                  </a>
                </p>
                <p>
                  {" "}
                  If you would like to find out more about cookies and other
                  similar technologies, please visit www.allaboutcookies.org, or
                  the Network Advertising Initiative online sources at
                  www.networkadvertising.org. We and our third-party partners
                  may also use cookies and tracking technologies for advertising
                  purposes. Please note that deleting or blocking cookies may
                  not be effective for all types of tracking technologies, such
                  as Local Storage Objects (LSOs) like HTML5.{" "}
                </p>
              </li>
              <li>
                <p>RACKING TECHNOLOGIES USED IN OUR EMAILS </p>
                <p>
                  {" "}
                  Our emails may contain tracking pixels that identify if and
                  when you have opened an email that we have sent you, how many
                  times you have read it and whether you have clicked on any
                  links in that email. This helps us measure the effectiveness
                  of our marketing email campaigns, make the emails we send to
                  you more relevant to your interests and to understand if you
                  have opened and read any important administrative emails we
                  might send you. <br /> Most popular email clients will allow
                  you to block these pixels by disabling certain external images
                  in emails. You can do this through the settings on your email
                  client - these generally give you the option of choosing
                  whether emails will display “remote images", “remote content"
                  or “images" by default. <br /> Some browsers also give you the
                  option of downloading and installing extensions that block
                  pixels and other tracking technologies.{" "}
                </p>
              </li>
            </ol>
            <h6 className="my-5 fw-semibold text-center pt-5">
              ANNEX 1 - PERSONAL INFORMATION YOU PROVIDE US
            </h6>
            <div className="d-md-block d-none">
              <div className="row fw-semibold desk-tables">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Category of Personal Information{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    How we may use the Personal Information{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1"> Legal Bases for processing </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Recipients of Personal Information{" "}
                  </div>
                </div>
              </div>
              <div className="row fw-semibold">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Contact information, such as first name, last name and email
                    address.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to set up and authenticate your
                    account on the European Services.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to communicate with you,
                    including sending service-related communications.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to deal with enquiries and
                    complaints made by or about you relating to the European
                    Services.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information in connection with providing you
                    with marketing communications in accordance with your
                    preferences.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for the performance of a
                    contract with you and to take steps prior to entering into a
                    contract with you, namely our Terms of Service.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for the performance of a
                    contract with you, namely our Terms of Service.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely administering the European Services, and for
                    communicating with you effectively to respond to your
                    queries or complaints.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We will only use your personal information in this way to
                    the extent you have given us consent to do so.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may share this information with Stripe, Inc. in order to
                    identify you so that you can make and receive payments
                    through the European Services. We may also share your
                    personal information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                </div>
              </div>
              <div className="row fw-semibold">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Your registration / account information such as your full
                    name, email, and password. If you are a teacher, we may also
                    ask for your gender, age, email address, languages spoken,
                    location, phone number, general background as part of the
                    “About You" section, and any web presence URL (e.g. Facebook
                    handle or LinkedIn URL).{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to create your account on the
                    European Services.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We use this information to deal with enquiries and
                    complaints made by or about you relating to the European
                    Services.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for the performance of a
                    contract with you.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely for communicating with our members effectively to
                    respond to any queries or complaints.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                </div>
              </div>
              <div className="row fw-semibold">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Payment transaction information. When you subscribe to our
                    services, we may collect information such as your billing
                    address and other information such as date and time of your
                    transaction.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to process your orders through
                    the European Services.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to verify your identity in
                    connection with the detection and prevention of fraud or
                    financial crime.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for the performance of a
                    contract.
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our and third partiers'
                    legitimate interests, namely the detection and prevention of
                    fraud and financial crime.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                </div>
              </div>
              <div className="row fw-semibold">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Approximate Location information. When you visit our
                    European Services, we may collect information about your
                    location. This information may be derived from WiFi
                    positioning or your IP address.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may use information to present the European Services to
                    you on your device.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to localise features of the
                    European Services.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for performance of a contract
                    with you.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interest,
                    namely localising features of the European Services and
                    tailoring the European Services so that it is more relevant
                    to our users.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    FingerprintJS, Inc.{" "}
                  </div>
                </div>
              </div>
              <div className="row fw-semibold">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Chat, comments and opinions. When you contact us directly,
                    e.g. by email or phone we will record your comments and
                    opinions.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to address your questions,
                    issues and concerns.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to improve the European
                    Services.
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely communicating with you and responding to queries,
                    complaints and concerns.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests (to
                    develop and improve our service).{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may share any information you provide to us when you
                    contact us with: Freshworks Inc. Discord Inc. Telegram Group
                    Inc. Meltwater Outreach Corporation{" "}
                  </div>
                </div>
              </div>
              <div className="row fw-semibold">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Information received from third parties, such as social
                    networks. If you interact with us through a social network,
                    we may receive information from the social network such as
                    your name, profile information, and any other information
                    you permit the social network to share with third parties.
                    We use single sign-on ("SSO") to allow a user to
                    authenticate their account using one set of login
                    information. The data we receive is dependent on your
                    privacy settings with the social network.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to reshare content created
                    through the use of the European Services{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to authenticate you and allow
                    you to access the European Services.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests (to
                    develop our service and inform our marketing strategy){" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for the performance of a
                    contract with you.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                </div>
              </div>
              <div className="row fw-semibold">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Your Preferences, such as preferences set for notifications,
                    marketing communications, how the European Services is
                    displayed and the active functionalities on the European
                    Services.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We use this information to provide notifications, send news,
                    alerts and marketing communications and provide the European
                    Services in accordance with your choices.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We use this information to ensure that we comply with our
                    legal obligation to send only those marketing communications
                    to which you have consented.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interest,
                    namely ensuring the user receives the correct marketing and
                    other communications, and that this is displayed in
                    accordance with the user's preferences.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for compliance with a legal
                    obligation to which we are subject.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-md-none d-block">
              <div className="row fw-semibold">
                <div className="col-md-3 px-md-0 mb-4 annex">
                  <div className="border-1 p-2">
                    {" "}
                    Contact information, such as first name, last name and email
                    address.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    Your registration / account information such as your full
                    name, email, and password. If you are a teacher, we may also
                    ask for your gender, age, email address, languages spoken,
                    location, phone number, general background as part of the
                    “About You" section, and any web presence URL (e.g. Facebook
                    handle or LinkedIn URL).{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    Payment transaction information. When you subscribe to our
                    services, we may collect information such as your billing
                    address and other information such as date and time of your
                    transaction.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    Approximate Location information. When you visit our
                    European Services, we may collect information about your
                    location. This information may be derived from WiFi
                    positioning or your IP address.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    Chat, comments and opinions. When you contact us directly,
                    e.g. by email or phone we will record your comments and
                    opinions.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    Information received from third parties, such as social
                    networks. If you interact with us through a social network,
                    we may receive information from the social network such as
                    your name, profile information, and any other information
                    you permit the social network to share with third parties.
                    We use single sign-on ("SSO") to allow a user to
                    authenticate their account using one set of login
                    information. The data we receive is dependent on your
                    privacy settings with the social network.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    Your Preferences, such as preferences set for notifications,
                    marketing communications, how the European Services is
                    displayed and the active functionalities on the European
                    Services.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    Payment transaction information. When you subscribe to our
                    services, we may collect information such as your billing
                    address and other information such as date and time of your
                    transaction.{" "}
                  </div>
                </div>
                <div className="col-md-3 px-md-0 mb-4 annex text-center">
                  <div className="border-1 p-2">
                    {" "}
                    How we may use the Personal Information{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to set up and authenticate your
                    account on the European Services.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to communicate with you,
                    including sending service-related communications.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to deal with enquiries and
                    complaints made by or about you relating to the European
                    Services.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information in connection with providing you
                    with marketing communications in accordance with your
                    preferences.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to create your account on the
                    European Services.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We use this information to deal with enquiries and
                    complaints made by or about you relating to the European
                    Services.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We use this information to deal with enquiries and
                    complaints made by or about you relating to the European
                    Services.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to verify your identity in
                    connection with the detection and prevention of fraud or
                    financial crime.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use information to present the European Services to
                    you on your device.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to address your questions,
                    issues and concerns.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to improve the European
                    Services.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to reshare content created
                    through the use of the European Services{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to reshare content created
                    through the use of the European Services{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We use this information to provide notifications, send news,
                    alerts and marketing communications and provide the European
                    Services in accordance with your choices.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We use this information to ensure that we comply with our
                    legal obligation to send only those marketing communications
                    to which you have consented.{" "}
                  </div>
                </div>
                <div className="col-md-3 px-md-0 mb-4 annex">
                  <div className="border-1 p-2">
                    {" "}
                    Legal Bases for processing{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for the performance of a
                    contract with you and to take steps prior to entering into a
                    contract with you, namely our Terms of Service.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for the performance of a
                    contract with you, namely our Terms of Service.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely administering the European Services, and for
                    communicating with you effectively to respond to your
                    queries or complaints.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We will only use your personal information in this way to
                    the extent you have given us consent to do so.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for the performance of a
                    contract with you.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely for communicating with our members effectively to
                    respond to any queries or complaints.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for the performance of a
                    contract.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our and third partiers'
                    legitimate interests, namely the detection and prevention of
                    fraud and financial crime.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for performance of a contract
                    with you.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interest,
                    namely localising features of the European Services and
                    tailoring the European Services so that it is more relevant
                    to our users.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely communicating with you and responding to queries,
                    complaints and concerns.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests (to
                    develop and improve our service).{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests (to
                    develop our service and inform our marketing strategy){" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for the performance of a
                    contract with you.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interest,
                    namely ensuring the user receives the correct marketing and
                    other communications, and that this is displayed in
                    accordance with the user's preferences.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for compliance with a legal
                    obligation to which we are subject.{" "}
                  </div>
                </div>
                <div className="col-md-3 px-md-0 mb-4 annex">
                  <div className="border-1 p-2">
                    {" "}
                    Recipients of Personal Information{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may share this information with Stripe, Inc. in order to
                    identify you so that you can make and receive payments
                    through the European Services. We may also share your
                    personal information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    FingerprintJS, Inc.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may share any information you provide to us when you
                    contact us with: Freshworks Inc. Discord Inc. Telegram Group
                    Inc. Meltwater Outreach Corporation{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    Unbounce Marketing Solutions, Inc.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    FingerprintJS, Inc.{" "}
                  </div>
                </div>
              </div>
            </div>
            <h6 className="my-5 fw-semibold text-center pt-5">
              ANNEX 2 - PERSONAL INFORMATION YOU PROVIDE US
            </h6>
            <div className="d-md-block d-none">
              <div className="row fw-semibold desk-tables">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Category of Personal Information{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    How we may use the Personal Information{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1"> Legal Bases for processing </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Recipients of Personal Information{" "}
                  </div>
                </div>
              </div>
              <div className="row fw-semibold desk-tables">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Approximate location information. Other than the information
                    you choose to provide to us, we do not collect information
                    about your precise location. Your device's IP address may
                    however help us determine an approximate location.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may use information you provide to us about your location
                    to monitor and detect fraud or suspicious activity in
                    relation to your Unstoppable Domain account.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to tailor how the European
                    Services is displayed to you (such as the language in which
                    it is provided to you).{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to protect our business and your account from fraud
                    and other illegal activities.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interest,
                    namely tailoring our service so that it is more relevant to
                    our users.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table border-bottom-0">
                  <div className="p-3 b-1">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    FingerprintJS, Inc.{" "}
                  </div>
                </div>
              </div>
              <div className="row fw-semibold desk-tables">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Information about how you access and use the European
                    Services. For example, how frequently you access the
                    European Services, the time you access the European Services
                    and how long you use it for, the approximate location that
                    you access the European Services from, the site from which
                    you came and the site to which you are going when you leave
                    our website, the website pages you visit, the links you
                    click, whether you open emails or click the links contained
                    in emails, whether you access the European Services from
                    multiple devices, and other actions you take on the European
                    Services.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may use information about how you use and connect to the
                    European Services to present the European Services to you on
                    your device.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to determine products and
                    services that may be of interest to you for marketing
                    purposes.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to monitor and improve the
                    European Services and business, resolve issues and to inform
                    the development of new products and services.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to tailor the European Services to the user.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to inform our direct marketing.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to monitor and resolve issues with the European
                    Services and to improve the European Services generally.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table border-bottom-0 border-top-0"></div>
              </div>
              <div className="row fw-semibold desk-tables">
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    Log files and information about your device. We also collect
                    information about the tablet, smartphone or other electronic
                    device you use to connect to the European Services. This
                    information can include details about the type of device,
                    unique device identifying numbers, operating systems,
                    browsers and applications connected to the European Services
                    through the device, your mobile network, your IP address and
                    your device's telephone number (if it has one).{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    We may use information about how you use and connect to the
                    European Services to present the European Services to you on
                    your device.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    We may use this information to monitor and improve the
                    European Services and business, resolve issues and to inform
                    the development of new products and services.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table">
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to tailor the European Services to the user.{" "}
                  </div>
                  <div className="p-3 b-1">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to monitor and resolve issues with the European
                    Services and to improve the European Services generally.{" "}
                  </div>
                </div>
                <div className="col-md-3 sec-table border-top-0"></div>
              </div>
            </div>
            <div className="d-md-none d-block">
              <div className="row fw-semibold">
                <div className="col-md-3 px-md-0 mb-4 annex">
                  <div className="border-1 p-2">
                    {" "}
                    Contact information, such as first name, last name and email
                    address.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    Approximate location information. Other than the information
                    you choose to provide to us, we do not collect information
                    about your precise location. Your device's IP address may
                    however help us determine an approximate location.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    Information about how you access and use the European
                    Services. For example, how frequently you access the
                    European Services, the time you access the European Services
                    and how long you use it for, the approximate location that
                    you access the European Services from, the site from which
                    you came and the site to which you are going when you leave
                    our website, the website pages you visit, the links you
                    click, whether you open emails or click the links contained
                    in emails, whether you access the European Services from
                    multiple devices, and other actions you take on the European
                    Services.
                  </div>
                  <div className="border-1 p-2">
                    Log files and information about your device. We also collect
                    information about the tablet, smartphone or other electronic
                    device you use to connect to the European Services. This
                    information can include details about the type of device,
                    unique device identifying numbers, operating systems,
                    browsers and applications connected to the European Services
                    through the device, your mobile network, your IP address and
                    your device's telephone number (if it has one).
                  </div>
                </div>
                <div className="col-md-3 px-md-0 mb-4 text-center annex text-center">
                  <div className="border-1 p-2">
                    {" "}
                    How we may use the Personal Information{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use information you provide to us about your location
                    to monitor and detect fraud or suspicious activity in
                    relation to your Unstoppable Domain account.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to tailor how the European
                    Services is displayed to you (such as the language in which
                    it is provided to you).{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use information about how you use and connect to the
                    European Services to present the European Services to you on
                    your device.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to determine products and
                    services that may be of interest to you for marketing
                    purposes.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to monitor and improve the
                    European Services and business, resolve issues and to inform
                    the development of new products and services.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use information about how you use and connect to the
                    European Services to present the European Services to you on
                    your device.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may use this information to monitor and improve the
                    European Services and business, resolve issues and to inform
                    the development of new products and services.
                  </div>
                </div>
                <div className="col-md-3 px-md-0 mb-4 annex">
                  <div className="border-1 p-2">
                    {" "}
                    Legal Bases for processing{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to protect our business and your account from fraud
                    and other illegal activities.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interest,
                    namely tailoring our service so that it is more relevant to
                    our users.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to tailor the European Services to the user.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to inform our direct marketing.{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to monitor and resolve issues with the European
                    Services and to improve the European Services generally.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to tailor the European Services to the user.
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    The processing is necessary for our legitimate interests,
                    namely to monitor and resolve issues with the European
                    Services and to improve the European Services generally.
                  </div>
                </div>
                <div className="col-md-3 px-md-0 mb-4 annex">
                  <div className="border-1 p-2">
                    {" "}
                    Recipients of Personal Information{" "}
                  </div>
                  <div className="border-1 p-2">
                    {" "}
                    We may share this information with:{" "}
                    <ul className="pl-3 my-3">
                      <li>Impact Tech, Inc.</li>
                      <li>Google LLC</li>
                      <li>Facebook, Inc.</li>
                      <li>Mode Analytics, Inc.</li>
                      <li>Tableau Software, LLC</li>
                      <li>Heap Analytics</li>
                      <li>Reddit, Inc.</li>
                      <li>Brave Software, Inc.</li>
                      <li>Twitter, Inc.</li>
                      <li>LinkedIn Coproration</li>
                      <li>Microsoft Corporation</li>
                      <li>Outreach Corporation</li>
                      <li>Cybba, Inc.</li>
                      <li>Hotjar Ltd.</li>
                      <li>Optimizely, Inc.</li>
                      <li>Peaberry Software, Inc.</li>
                      <li>Snap, Inc.</li>
                      <li>TikTok, Inc.</li>
                      <li>Twitch Interactive, Inc.</li>
                    </ul>{" "}
                    FingerprintJS, Inc.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
