import {  faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_UpdateTemplate, API_UpdateTrasaction } from "api-services/Transactions/API_UpdateTemplate";
import { TransactionStatus } from "helpers/enum";
import { PaymentStatusBadge, getCurrencySymbol } from "helpers/helper";
import React, { useState } from "react";
import { Badge, Button, Modal, Spinner, Toast } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function MyOrders(transaction) {
  const [show, setShow] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const { usersTransactions } = useSelector((state) => state.user.dbUser);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedOption, setSelectedOption] = useState('0');

  console.log("User Transactions", usersTransactions);
  const handleConfirmClose = (show) => {
    setShowConfirm(show);
  };
  const handleClose = () => setShow(false);

  const handleShow = (transaction) => {
    setSelectedTransaction(transaction);
    setSelectedOption(transaction?.templateSelection?.toString())
    setShow(true);
  };

  const handleConfirm = async (confirm) => {
    // setUserConfirmed(confirm);
    // if (confirm) {
      // API and Loading..
      debugger;
      setIsLoading(true);
      handleConfirmClose(false);
      var result = await API_UpdateTemplate(selectedTransaction.id, selectedOption);
      if (result.success) {
        toast("The template has been updated.", {
          autoClose: 3000,
          type: "success",
        });
        setShow(false);
      } else {
        toast("something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      }

      setIsLoading(false);
    //}
  };
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <>
      <table className="table table-sm table-hover table-bordered table-striped table-orders">
        <caption>
          Please contact us, if you find any mistake.{" "}
          <Link className="btn-sm-slim btn-primary" to="/contact-us">
            Contact us
          </Link>
        </caption>
        <thead className="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Secret</th>
            <th scope="col">Domain</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {usersTransactions &&
            usersTransactions?.map((item, index) => (
              <tr key={index}  className="clickable-row">
                <td title={item?.id}>{item?.id}</td>
                <td title={item?.transactionID}>
                  <Badge className="secretKey bg-gradient-protected">
                    {item?.transactionID || ""}
                  </Badge>
                  
                </td>
                <td>{item.itemName}
                {item.status === TransactionStatus.Paid && 
                (<Button onClick={() => handleShow(item)} className="ml-1 btn-sm-slim"  variant="danger"> Edit Template 
                <FontAwesomeIcon
                className={"icon-fontawesome-fix"}
                icon={faEdit}
                />
                </Button>)} 
                </td>
                <td>
                  {item?.currency &&
                    getCurrencySymbol("en-US", item?.currency) + item?.amount}
                </td>
                <td>
                  <Badge bg={PaymentStatusBadge(item.status).type}>
                    {PaymentStatusBadge(item.status).text}
                  </Badge>
                </td>
                <td>{new Date(item.createdOn).toLocaleDateString("en-US")}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title >Please Select the Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="radioImage">
          <label>
            <input type="radio" name="tempSelect" value="1" 
          checked={selectedOption === '1'}
          onChange={handleChange} />
          <img src="/assets/images/bulid-by-yourself.png" alt="Option 1" />
        <img />
      </label>
        <label>
            <input type="radio" name="tempSelect" value="2"
            checked={selectedOption === '2'}
            onChange={handleChange} />
            <img src="/assets/images/Temp1.png" alt="Option 1" />
        </label>
        <label>
              <input type="radio" name="tempSelect" value="3"
          checked={selectedOption === '3'}
          onChange={handleChange}/>
            <img src="/assets/images/Temp2.png" alt="Option 1" />
        </label>
        <label>
              <input type="radio" name="tempSelect" value="4"
          checked={selectedOption === '4'}
          onChange={handleChange} />
            <img src="/assets/images/Temp3.png" alt="Option 1" />
        </label>
        <label>
              <input type="radio" name="tempSelect" value="5"
          checked={selectedOption === '5'}
          onChange={handleChange} />
            <img src="/assets/images/Temp4.png" alt="Option 1" />
        </label>
        <label>
              <input type="radio" name="tempSelect" value="6"
            checked={selectedOption === '6'}
          onChange={handleChange} />
            <img src="/assets/images/Temp5.png" alt="Option 1" />
        </label>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="gold text-white" onClick={handleConfirm} >
          {isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Update
          </Button>
          <Button className="gold text-white" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
